import { Component } from '@angular/core';
import { UOM } from '../../../../models';
import { ProductFacade, SelectedProduct } from '../../../../services/product-facade';
import { DecimalMath } from '../../../../utils/utils';
import { BaseQtyItemComponent } from '../base-qty-item.component';
import { SVGIcon, chevronDownIcon } from '@progress/kendo-svg-icons';



@Component({
  selector: 'app-random-item',
  templateUrl: './random-item.component.html',
  styleUrls: ['../base-qty-item.component.scss']
})
export class RandomItemComponent extends BaseQtyItemComponent {

  public chevronDown: SVGIcon = chevronDownIcon;


  readonly scrapPrecent: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(public override productFacade: ProductFacade) {
    super(productFacade);
  };

  get visibleUOMs(): Array<UOM> {
    return this.part.partUOMs.filter(r => r.visible == true);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    
    this.subscriptions.add(this.qtyItemForm.controls.sellingUOM.valueChanges.subscribe(sellingUOM => {
      this.selectedUOM = this.part.partUOMs.find(r => r.uomCode === sellingUOM);
      if (this.selectedUOM.applyLossPercent) {
        this.qtyItemForm.controls.scrap.enable();
      }
      else {
        this.qtyItemForm.controls.scrap.setValue(0);
        this.qtyItemForm.controls.scrap.disable();
      }
    
    }));

    
    if (!this.selectedUOM.applyLossPercent) {
      this.qtyItemForm.controls.scrap.disable({ emitEvent:false });
    }
  }

  setFormValues(r: SelectedProduct): void {
    this.qtyItemForm.controls.sellingUOM.setValue(r.sellingUOM, { emitEvent: false });
    this.qtyItemForm.controls.sellingUOMDesc.setValue(r.sellingUOMDesc, { emitEvent: false });
    this.selectedUOM = this.part.partUOMs.find(x => x.visible && x.uomCode === r.sellingUOM);

    this.qtyItemForm.controls.qty.setValue(r.qty);
    this.qtyItemForm.controls.scrap.setValue(r.scrap);
  }

 
  computeTotalSellingQty(): number {

    const sellingUOM = this.part.partUOMs.find(r => r.uomCode === this.qtyItemForm.controls.sellingUOM.value);
    const desiredQty = this.qtyItemForm.controls.qty.value;
    const scrapPct = this.qtyItemForm.controls.scrap.value / 100;
    const sellingQty = desiredQty + (desiredQty * scrapPct);
    let totalSellingQty: number;

    switch (sellingUOM.rounding) {
      case "RND":
        totalSellingQty = DecimalMath.round(sellingQty, sellingUOM.numOfDec);
        break;
      case "UP":
        totalSellingQty = DecimalMath.round(sellingQty, sellingUOM.numOfDec);
        break;
      case "DWN":
        totalSellingQty = DecimalMath.round(sellingQty, sellingUOM.numOfDec);
        break;
      default:
        totalSellingQty = DecimalMath.trunc(sellingQty, sellingUOM.numOfDec);
    }
    
    // Compute default uom qty
    if (sellingUOM.uomCode == this.part.defaultUOM) {
      this.qtyItemForm.controls.totalDefaultUOMQty.setValue(totalSellingQty);
    }
    else {
      const defaultUOM = this.part.partUOMs.find(r => r.uomCode === this.part.defaultUOM);
      let totalDefaultUOMQty = totalSellingQty * sellingUOM.convFactor;

      switch (defaultUOM.rounding) {
        case "RND":
          totalDefaultUOMQty = DecimalMath.round(totalDefaultUOMQty, defaultUOM.numOfDec);
          break;
        case "UP":
          totalDefaultUOMQty = DecimalMath.round(totalDefaultUOMQty, defaultUOM.numOfDec);
          break;
        case "DWN":
          totalDefaultUOMQty = DecimalMath.round(totalDefaultUOMQty, defaultUOM.numOfDec);
          break;
        default:
          totalDefaultUOMQty = DecimalMath.trunc(totalDefaultUOMQty, defaultUOM.numOfDec);
      }
      this.qtyItemForm.controls.totalDefaultUOMQty.setValue(totalDefaultUOMQty);
    }
    
    return totalSellingQty;

  }

}
