import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GlobalState, GlobalStateService } from '../services/global-state.service';

@Injectable({
  providedIn: 'root'
})
export class QueryParamGuard  {

  constructor(private router: Router, private globalStateSvc: GlobalStateService) { }

  canActivate(
    route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const params = route.queryParams;

    if (environment.production) {
      const state: GlobalState = {
          custNum: params['custNum'],
          shipToNum: params['shipToNum'],
          orderNum: params['orderNum'],
          company: params['comp'],
          partNum: params['partNum'],
          lang: params['lang'] ? params['lang'] : null
      };
      this.globalStateSvc.updateQueryParams(state);
    }
    else {
      const state: GlobalState = {
        custNum: params['custNum'] ? params['custNum'] : 438,
        shipToNum: params['shipToNum'] ? params['shipToNum'] : "",
        orderNum: params['orderNum'] ? params['orderNum'] : 0,
        company: params['comp'] ? params['comp'] : "MCAD",
        partNum: params['partNum'] ? params['partNum'] : "",
        lang: params['lang'] ? params['lang'] : null
      };
      this.globalStateSvc.updateQueryParams(state);
      
    }

    if (!this.globalStateSvc.isQueryStringValid()) {
      this.router.navigate(['error/400'], { state: { message: "Invalid Query String" } });
      return false
    }
    return true;
  }

}
