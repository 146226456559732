import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { BehaviorSubject, distinctUntilChanged, finalize, map, Observable } from 'rxjs';
import { CompProductState } from './comp-product-facade';
import { ColorStepState, ProductState, QuantityStepState } from './product-facade';
import { Product, CompProduct } from '../models';
import { GlobalStateService } from '../../services/global-state.service';
import { Router } from '@angular/router';
import { DecimalMath } from '../utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@progress/kendo-angular-notification';

export interface MainProduct {
  partNum: string;
  partDescription: string;
  isMaibecExpress: boolean;
  selectedProductImageUrl: string;
  totalSellingQty: number;
  sellingUOM: string;
  sellingUOMDesc: string;
  specificLength: string;
  colorDesc: string;
  sampleColorNumber: string;
  colorHex: string;
  colorImageUrl: string;
  uD_RefCo_c?: string;
  uD_ColorCode_c?: string;
  uD_ColorBrand_c?: string;
  uD_ColorName_c?: string;
  isExpressColor: boolean;
  comments: string;
  uOMConvertMessage: string;
  compProducts?: MainProduct[];
};

export interface OrderSummary {
  invalidSKU: string[];
  mainProducts: MainProduct[];
};

export interface OrderSummaryStepState {
  orderNum: number;
  orderSummary: OrderSummary;
  saving: boolean;
  saved: boolean;
};

const initialOrderSummaryState: OrderSummaryStepState = {
  orderNum: 0,
  orderSummary: {
    invalidSKU: [],
    mainProducts: []
  },
  saving: false,
  saved: false
};


@Injectable({
  providedIn: 'root'
})
export class OrderFacade {

  readonly baseColorImagePath: string = `${environment.webApiUrl}/Images/Color`;

  private store = new BehaviorSubject<OrderSummaryStepState>(initialOrderSummaryState);
  private state$ = this.store.asObservable();

  /////////////////////////////////////////////////////////////////////////////
  // Summary Step Stream
  /////////////////////////////////////////////////////////////////////////////
  orderSummary$ = this.state$.pipe(
    map(state => { return state.orderSummary }),
    distinctUntilChanged()
  );

  saving$ = this.state$.pipe(
    map(state => { return state.saving }),
    distinctUntilChanged()
  );

  saved$ = this.state$.pipe(
    map(state => { return state.saved; }),
    distinctUntilChanged()
  );

  constructor(
    private http: HttpClient,
    private glbSvc: GlobalStateService,
    private translate: TranslateService,
    private notificationSvc: NotificationService,
    private router: Router,
    private window: Window) {

    const orderNum = glbSvc.orderNum
    this.updateState({ ...this.state, orderNum });

  };

  get state(): OrderSummaryStepState {
    return this.store.getValue();
  }

  private getColorDesc(colorStepState: ColorStepState): string {

    let colorDesc = "";
    const colorCode = colorStepState.isCustomColor ? colorStepState.customColorCode : colorStepState.selectedColor.colorId;
    const colorName = colorStepState.isCustomColor ? colorStepState.customColorName : colorStepState.selectedColor.colorName;
    if (colorCode !== "") {
      colorDesc += colorCode + " - ";
    }
    colorDesc += colorName;
    return colorDesc;
  };

  public buildSummary(productState: ProductState, compProductState: CompProductState): void {

    const orderSummaryState = { ...this.state };

    const colorStepState: ColorStepState = productState.colorStepState;
    const refCo = colorStepState.refCo == null ? "" : colorStepState.refCo;
    const colorCode = colorStepState.isCustomColor ? colorStepState.customColorCode : colorStepState.selectedColor.colorId;
    const colorBrand = colorStepState.isCustomColor ? colorStepState.customColorBrandId : colorStepState.selectedColor.brandId;
    const colorName = colorStepState.isCustomColor ? colorStepState.customColorName : "";
    const colorHex = colorStepState.isCustomColor ? "" : colorStepState.selectedColor.bgHexCode;
    const isExpressColor = colorStepState.isCustomColor ? false : colorStepState.selectedColor.isExpressColor;
    const colorImageUrl = colorStepState.isCustomColor ? "" : colorStepState.selectedColor.imageUrl === '' ? "" : `${this.baseColorImagePath}/${colorCode}/${colorStepState.selectedColor.imageUrl}`;
    const sampleColorNumber = colorStepState.colorSampleNumber;

    const selectedProduct = productState.quantityStepState.selectedProduct;
    let selectedProductImageUrl = "";
    const productImageUrl = productState.steps.find(r => r.isLayer === true);
    if (productImageUrl) {
      selectedProductImageUrl = productImageUrl.selectedLevelImage;
    }
    orderSummaryState.orderSummary.invalidSKU = [];

    let lsComment = "";

    if (selectedProduct.qtyType === 'specific') {
      lsComment = selectedProduct.comment
    }


    const mainProduct: MainProduct = {
      partNum: selectedProduct.partNum,
      partDescription: selectedProduct.partDesc,
      isMaibecExpress: selectedProduct.isMaibecExpress,
      selectedProductImageUrl: selectedProductImageUrl,
      totalSellingQty: selectedProduct.totalSellingQty,
      sellingUOM: selectedProduct.sellingUOM,
      sellingUOMDesc: selectedProduct.sellingUOMDesc,
      specificLength: lsComment,
      sampleColorNumber: sampleColorNumber,
      colorHex: colorHex,
      colorImageUrl: colorImageUrl,
      colorDesc: this.getColorDesc(colorStepState),
      uD_RefCo_c: refCo.toUpperCase(),
      uD_ColorCode_c: colorCode,
      uD_ColorBrand_c: colorBrand,
      uD_ColorName_c: colorName,
      isExpressColor: isExpressColor,
      comments: lsComment,
      uOMConvertMessage: "",
      compProducts: []
    };
    const steps = compProductState.steps;
       
    for (const step of steps) {
      if (step.selectedCompParts) {
        for (const selectedCompPart of step.selectedCompParts) {
          const cmpProd: CompProduct = step.compProducts.find(r => r.childPartNum === selectedCompPart);
          if (cmpProd) {
            var autoConversion = this.translate.instant("autoConversion");
            var to = this.translate.instant("to");

            // Check if only one UOM 
            if (cmpProd.priceListUOMs.split(",").length > 1) {
              orderSummaryState.orderSummary.invalidSKU.push(`${cmpProd.childPartNum} - ${cmpProd.childDesc}`); 
            }
            else {

              const compPart: MainProduct = {
                partNum: selectedCompPart,
                partDescription: cmpProd.childDesc,
                selectedProductImageUrl: "",
                isMaibecExpress: cmpProd.childIsMaibecExpress,
                totalSellingQty: cmpProd.childUOM == cmpProd.priceListUOMs ? cmpProd.qty : this.convertToPricelistUOM(cmpProd),
                sellingUOM: cmpProd.priceListUOMs,
                sellingUOMDesc: cmpProd.priceListUOMs,
                specificLength: "",
                sampleColorNumber: sampleColorNumber,
                colorHex: colorHex,
                colorImageUrl: colorImageUrl,
                colorDesc: cmpProd.childCodeFinish != "NA" ? this.getColorDesc(colorStepState): "",
                uD_RefCo_c: cmpProd.childCodeFinish != "NA" ? refCo.toUpperCase() : "",
                uD_ColorCode_c: cmpProd.childCodeFinish != "NA" ? colorCode : "",
                uD_ColorBrand_c: cmpProd.childCodeFinish != "NA" ? colorBrand : "",
                uD_ColorName_c: cmpProd.childCodeFinish != "NA" ? colorName : "",
                isExpressColor: isExpressColor,
                uOMConvertMessage: cmpProd.childUOM == cmpProd.priceListUOMs ? "" : `${autoConversion} ${cmpProd.qty} ${cmpProd.childUOM} ${to} ${this.convertToPricelistUOM(cmpProd)} ${cmpProd.priceListUOMs}`,
                comments: ""
              }
              mainProduct.compProducts.push(compPart);
            }
          }
        }
      }
    }
    orderSummaryState.orderSummary.mainProducts.push(mainProduct);
 
    this.updateState(orderSummaryState);

  }

  private convertToPricelistUOM(cmpProd: CompProduct): number {
    const priceListUOM = cmpProd.partUOMs.find(r => r.uomCode === cmpProd.priceListUOMs);
    const originalQty = cmpProd.qty;
    const factor = priceListUOM.convFactor;
    const newQty = Math.ceil(originalQty / factor);

    switch (priceListUOM.rounding) {
      case "RND":
        return DecimalMath.round(newQty, priceListUOM.numOfDec);
        break;
      case "UP":
        return DecimalMath.round(newQty, priceListUOM.numOfDec);
        break;
      case "DWN":
        return DecimalMath.round(newQty, priceListUOM.numOfDec);
        break;
      default:
        return DecimalMath.trunc(newQty, priceListUOM.numOfDec);
    }
  }


  public sendOrder(): void {
    var from = this.glbSvc.tokenType.toUpperCase();
    switch (from) {
      case "EPICOR":
        this.sendToEpicor();
        break;
      case "CNP":
        this.sendToSoumissionneur();
        break;

      default:
        this.router.navigate(['error/400'], { state: { message: "Invalid Origin" } });
        return;
    }


  }

  private sendToSoumissionneur(): void {
    this.updateState({ ...this.state, saving: true });

    var orderSummary = { ...this.state.orderSummary };
    delete orderSummary.invalidSKU;

    this.window.parent.postMessage(orderSummary, "*");

    this.updateState({ ...this.state, saved: true })

  }

  private sendToEpicor(): void {

    this.updateState({ ...this.state, saving: true });

    this.saveOrderLines().pipe(
      finalize(() => this.updateState({ ...this.state, saving: false }))
    ).subscribe(() => {
      this.updateState({ ...this.state, saved: true });
    });
  }

  public resetOrder(): void {

    let newState = { ...this.state, orderSummary: { mainProducts: [], invalidSKU:[] } }

    this.updateState(newState);

  }

  /////////////////////////////////////////////////////////////////////////////
  // Private Methods
  /////////////////////////////////////////////////////////////////////////////
  private updateState(newState: OrderSummaryStepState) {
    this.store.next(newState);
  }

  private saveOrderLines(): Observable<boolean> {
    return this.http.post<boolean>(`${environment.webApiUrl}/Order?orderNum=${this.state.orderNum}`, this.state.orderSummary);
  }

}
