<label class="cnp-color-card"
       [class.cnp-color-card-active]="value === color.colorId"
       [class.cnp-color-card-focused]="focused === color.colorId">
  <img src="{{imgSource}}" style="height:60px" [ngStyle]="{'background-color':color.bgHexCode}">
  <div style="display:flex; flex-flow:row; align-items:center;font-size:11px; gap:8px;">
    <input kendoRadioButton 
           type="radio"
           name="selectedColor"
           (blur)="onBlur()"
           (focus)="onFocus(color.colorId)"
           [size]="'large'"/>
    <div style="color:#384B52">{{color.colorName}} {{color.colorId}}</div>
  </div>
</label>

