<ng-container *ngIf="(parts$ | async) as parts">
  <div style="padding:0 15px 0 15px; display:flex; flex-flow:column; gap:15px;">
    <form [formGroup]="selectedProductForm"></form>
    <div *ngFor="let part of parts; let index = index;" [ngSwitch]="part.qtyType">
      <app-random-item *ngSwitchCase="'assortie'" [selectedProductForm]="selectedProductForm" [part]="part"></app-random-item>
      <app-fixed-item *ngSwitchCase="'fixe'" [selectedProductForm]="selectedProductForm" [part]="part"></app-fixed-item>
      <app-specific-item *ngSwitchCase="'specific'" [selectedProductForm]="selectedProductForm" [part]="part"></app-specific-item>
    </div>
    <div>
      <button kendoButton
              class="cnp-button-primary"              
              [disabled]="disableNextStep()"
              (click)="goToCompProductStep()">
        {{ 'btnNextStep' | translate }}
      </button>
    </div>
  </div>
</ng-container>
