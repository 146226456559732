import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

export interface GlobalState {
  custNum: number;
  shipToNum: string;
  orderNum: number;
  company: string;
  partNum: string;
  lang?: string;
}

let state: GlobalState = {
  custNum: 0,
  shipToNum: "",
  orderNum: 0,
  company: "",
  partNum: "",
  lang: null
};

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {

  private store = new BehaviorSubject<GlobalState>(state);
  private state$ = this.store.asObservable();

  public get isAuthenticated(): boolean {
    return this.authSvc.isAuthenticated
  }

  public get userName(): string {
    return this.authSvc.userName;
  }

  public get langNameID(): string {
    
    return this.store.value.lang ?? this.authSvc.langNameID;
  }

  public get appReady(): boolean {
    return this.isAuthenticated;
  }

  public get custNum(): number {
    return this.store.value.custNum;
  }

  public get shipToNum(): string {
    return this.store.value.shipToNum;
  }

  public get tokenType(): string {
    return this.authSvc.tokenType
  }

  public get orderNum(): number {
    return this.store.value.orderNum;
  }

  public get partNum(): string {
    return this.store.value.partNum;
  }

  public get company(): string {
    return this.store.value.company;
  }

  constructor(private authSvc: AuthenticationService) { }

  public updateQueryParams(newState: GlobalState) {
    this.store.next(state = newState);
  }

  public isQueryStringValid(): boolean {
    const isInvalid =
      this.store.value.custNum === 0 ||
      this.store.value.custNum === undefined ||
      this.store.value.shipToNum === undefined ||
      this.store.value.company === "" ||
      this.store.value.company === undefined;

    return !isInvalid;
  }
}

