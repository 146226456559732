<h1 class="primary no-margin" style="font-size:32px">{{'lblCompProducts' | translate }}</h1>
<kendo-stepper #stepperComp
               [steps]="(step$ | async)"
               stepType="full"
               [(currentStep)]="_currentStep"
               orientation="vertical"
               [linear]="false">
  <ng-template kendoStepperStepTemplate let-step let-i="index">
    <div class="comp-product-step">
      <div style="display: flex;margin-top: 23px;flex-flow: row; gap:13px;">        
        <div style="display:flex;flex-flow: column; color:#384B52">
          <span class="step-name" >{{step.description}}</span>
        </div>
      </div>
      <div class="edit"><a (click)="setCurrentStep(i)">{{'lblEdit' | translate }}</a></div>
    </div>
  </ng-template>

</kendo-stepper>
