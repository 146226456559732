import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { CompStep } from '../../models';
import { CompProductFacade } from '../../services/comp-product-facade';

@Component({
  selector: 'app-comp-product-stepper',
  templateUrl: './comp-product-stepper.component.html',
  styleUrls: ['./comp-product-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompProductStepperComponent {

  @HostBinding('class') class = 'comp-product-stepper';

  public _currentStep: number = 0;
  public step$: Observable<CompStep[]>;

  constructor(public facade: CompProductFacade) {
    this.step$ = facade.compSteps$
    facade.currentStepNumber$.subscribe(r => this._currentStep = r)
  }

  setCurrentStep(index: number) {
    this.facade.revertStepTo(index);

  }


}
