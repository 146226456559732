import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Product } from '../../models';
import { CompProductFacade } from '../../services/comp-product-facade';
import { ProductFacade, SelectedProduct } from '../../services/product-facade';

@Component({
  selector: 'app-quantity-step',
  templateUrl: './quantity-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class QuantityStepComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  parts$: Observable<Product[]>;

  selectedProductForm = new FormGroup({
    qtyType: new FormControl(''),
    partNum: new FormControl(''),
    partDesc: new FormControl(''),
    isMaibecExpress: new FormControl(false),
    sellingUOM: new FormControl(''),
    sellingUOMDesc:new FormControl(''),
    qty: new FormControl(0),
    totalDefaultUOMQty: new FormControl(0),
    totalSellingQty: new FormControl(0),  

    scrap: new FormControl(0),
    quantities: new FormArray([]),
    comment: new FormControl('')
  });

  constructor(
    private compProductFacade: CompProductFacade,
    public productFacade: ProductFacade) {

    this.parts$ = this.productFacade.parts$;
   
  };

  ngOnInit(): void {
    this.productFacade.fetchPartsForQty();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goToCompProductStep(): void {
 
    this.productFacade.updateSelectedMainProduct(this.selectedProductForm.value as SelectedProduct);
    this.compProductFacade.showRootCompProductStep(this.selectedProductForm.value.partNum, this.selectedProductForm.value.totalDefaultUOMQty);
  };

  disableNextStep(): boolean {
    const selectedPart = this.selectedProductForm.value.partNum;
    return this.selectedProductForm.invalid || selectedPart === '' || (selectedPart !== '' && this.selectedProductForm.controls.totalSellingQty.value === 0);
  }

}
