import { Component } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LevelItem } from '../../models/level-item';
import { ProductFacade } from '../../services/product-facade';


@Component({
  selector: 'app-product-step',
  templateUrl: './product-step.component.html'
})
export class ProductStepComponent {

  isLastStep$: Observable<boolean>;
  isColorStep$: Observable<boolean>;
  isQtyStep$: Observable<boolean>;
  items$: Observable<LevelItem[]>;

  currentStepDesc: string;

  readonly imageUrl: string = `${environment.webApiUrl}/Images/Level`

  constructor(
    private productFacade: ProductFacade) {

    this.items$ = this.productFacade.currentStepLevelItems$;
    this.isLastStep$ = this.productFacade.isLastStep$;
    this.isColorStep$ = this.productFacade.isColorStep$;
    this.isQtyStep$ = this.productFacade.isQtyStep$;
    this.productFacade.currentStep$.pipe(
      filter(r => r !== null),
      map(step => step)).subscribe(r => {
      
        this.currentStepDesc = r?.stepName;
      });
  }

 
  public selectItem(item: LevelItem) {
    this.productFacade.selectLevel(item);
  }
}
