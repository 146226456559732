import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalStateService } from '../../../services/global-state.service';
import { CompProductFacade } from '../../services/comp-product-facade';
import { OrderFacade, OrderSummary } from '../../services/order-facade';
import { ProductFacade } from '../../services/product-facade';
import { commentIcon, SVGIcon } from "@progress/kendo-svg-icons";
import { NotificationService } from '@progress/kendo-angular-notification';

commentIcon
@Component({
  selector: 'app-summary-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SummaryStepComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild("template", { read: TemplateRef })
  public notificationTemplate: TemplateRef<unknown>;


  public comment: SVGIcon = commentIcon;
  private subscriptions = new Subscription();

  orderSummary: OrderSummary;

  @ViewChild(NgForm) sumForm!: NgForm;

  constructor(
    public orderFacade: OrderFacade,
    private compProductFacade: CompProductFacade,
    private productFacade: ProductFacade,
    private notificationSvc: NotificationService,
    public glbSvc: GlobalStateService) {
  }
  ngAfterViewInit(): void {
    if (this.orderSummary.invalidSKU.length > 0) {

        this.notificationSvc.show({
          content: this.notificationTemplate,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'slide', duration: 800 },
          type: { style: 'error', icon: true },
          closable: true
        });
      }
    }


  ngOnInit(): void {
    this.orderFacade.buildSummary(this.productFacade.getSnapShot(), this.compProductFacade.getSnapShot());
    this.subscriptions.add(this.orderFacade.orderSummary$.subscribe(r => { this.orderSummary = r; }));   
  }
 
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  sendOrder(): void {
    this.orderFacade.sendOrder();
  }

  public goToQtyStep() {
    this.orderFacade.resetOrder();
    this.compProductFacade.hideCompProduct();
    this.productFacade.hideSummary();
    this.productFacade.goToQtyStep();
  };



}
