import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authSvc: AuthenticationService) {  
  }

  ngOnInit(): void {

    if (!this.authSvc.isAuthenticated) {
      const token = this.route.snapshot.queryParams['token'];
      //const userId = this.route.snapshot.queryParams['userId'];
     
      if ((!token /*|| !userId*/)) {      
        this.router.navigate(['error', 400], { queryParams: this.route.snapshot.queryParams })
      }
      else {
        this.authSvc.autoLogin(token/*, userId*/).subscribe(isValid => {
          if (isValid) {
            this.router.navigate(['/'], { queryParams: this.route.snapshot.queryParams })
          }
          else {
            // General error
            this.router.navigate(['error', 400], { queryParams: this.route.snapshot.queryParams })
          }
        });
      }
    }

  }
}
