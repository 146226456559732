import { Component, ViewEncapsulation } from '@angular/core';
import { CompProductFacade } from '../../services/comp-product-facade';
import { ProductFacade, } from '../../services/product-facade';

@Component({
  selector: 'app-product-stepper',
  templateUrl: './product-stepper.component.html',
  styleUrls: ['./product-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductStepperComponent {

  private _currentStep = 0;

  public get currentStep(): number {
    return this._currentStep
  }
  public set currentStep(value: number) {
    this._currentStep = value;
  }

  constructor(public productFacade: ProductFacade, private compProductFacade: CompProductFacade) {
    productFacade.currentStepNumber$.subscribe(r => { this.currentStep = r });
  }
   
  public setCurrentStep(e: number): void {    
    this.productFacade.revertStepTo(e);
    this.compProductFacade.hideCompProduct();
    this.compProductFacade.resetState();
  }

  public goToQtyStep() {

    this.compProductFacade.hideCompProduct();
    this.productFacade.goToQtyStep();
  };
    
}
