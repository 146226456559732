import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { GlobalStateService } from '../services/global-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private router: Router,
    private globalStateSvc: GlobalStateService,
    private translateSvc:TranslateService  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {

    if (this.globalStateSvc.isAuthenticated) {
      this.translateSvc.use(this.globalStateSvc.langNameID)
      return true;
    }
    else {
      this.router.navigate(['login'], { queryParams: route.queryParams });
      return false;
    }
  }
}

