import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html'

})
export class ErrorPageComponent {

  public errorCode;
  public message: string;

  constructor(private router: Router,
    private route: ActivatedRoute) {

    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { message: string };
    this.message = state?.message;

    this.route.params.subscribe(params => { this.errorCode = params['id']; });
  }
 
}
