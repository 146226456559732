import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ProductFacade, SelectedProduct } from '../../../../services/product-facade';
import { arrayNumberRange } from '../../../../utils/utils';
import { BaseQtyItemComponent } from '../base-qty-item.component';
import { SVGIcon, chevronDownIcon, plusIcon, trashIcon } from '@progress/kendo-svg-icons';


export const lsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  const ls = control.get('ls').value;
  if (ls && ls.length > 0) {
    const qty = control.get("qty").value;
    const length = control.get("length").value;
    const row = ls.find(r => r.length === length);
    const isValid = row && row.quantity >= qty;
    const error = (isValid) ? null : { ls: true, message: 'errMaxLsQty', maxQty: row.quantity };
    control.get("qty").setErrors(error);
    control.get("length").setErrors(error);
    return error;
  }
  else {
    return null
  }
}

@Component({
  selector: 'app-specific-item',
  templateUrl: './specific-item.component.html',
  styleUrls: ['../base-qty-item.component.scss']
})
export class SpecificItemComponent extends BaseQtyItemComponent {

  public trash: SVGIcon = trashIcon;
  public chevronDown: SVGIcon = chevronDownIcon
  public plus: SVGIcon = plusIcon;


  lengths: Array<number>;

  constructor(public override productFacade: ProductFacade,
    private fb: FormBuilder) {
    super(productFacade);
  };

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.part.lsLengths && this.part.lsLengths.length > 0) {
      this.lengths = this.part.lsLengths.map(r => r["length"]);
    }
    else {
      this.lengths = arrayNumberRange(this.part.minLength, this.part.maxLength);
    }
    this.subscriptions.add(this.qtyItemForm.controls.quantities.valueChanges.subscribe(() => {
      this.computeTotalQty();
    }));
  };



  setFormValues(r: SelectedProduct): void {
    this.qtyItemForm.controls.sellingUOM.setValue(r.sellingUOM, { emitEvent: false });
    this.qtyItemForm.controls.sellingUOMDesc.setValue(r.sellingUOMDesc, { emitEvent: false });
    this.qtyItemForm.controls.qty.setValue(r.qty);

    if (r.quantities && r.quantities.length > 0) {
      this.qtyItemForm.controls.quantities.clear();
      for (const value of r.quantities) {
        this.addLine(value);
      }
      this.computeTotalQty();
    }
  };

  computeTotalSellingQty(): number {
    this.qtyItemForm.controls.totalDefaultUOMQty.setValue(this.qtyItemForm.controls.qty.value);
    this.buildLSComment();
    return this.qtyItemForm.controls.qty.value
  };

  addLine(values?: { qty: number, length: number }): void {

    const quantityForm = this.fb.group({
      qty: [values?.qty ?? 0],
      length: [values?.length ?? this.lengths[0]],
      ls: new FormControl(this.part.lsLengths)
    }, {
      validators: lsValidator
    });
    this.qtyItemForm.controls.quantities.push(quantityForm);    
  };

  removeLineAt(index: number): void {
    this.qtyItemForm.controls.quantities.removeAt(index);
    this.computeTotalQty();
  };

  private buildLSComment() {
    let comment = "LS: ";
    this.qtyItemForm.controls.quantities.controls.forEach(x => {
      const qty = x.get("qty").value;
      const length = x.get("length").value;
      comment = comment + `${qty}/${length}; `;
    });
    this.qtyItemForm.controls.comment.setValue(comment);
  }

  private computeTotalQty() {
        
    let totalQty: number = 0;

    this.qtyItemForm.controls.quantities.controls.forEach(x => {
      const qty = x.get("qty").value;
      const length = x.get("length").value;
      totalQty += (qty * length);     
    });
    this.qtyItemForm.controls.qty.setValue(totalQty);   
  };

}
