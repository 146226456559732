import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { CompProductFacade } from '../../services/comp-product-facade';
import { ProductFacade } from '../../services/product-facade';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html'
})
export class StepperComponent {

  showCompProduct$: Observable<boolean>;
  isSummaryStep$: Observable<boolean>;

  constructor(private compProductFacade: CompProductFacade, private productFacade: ProductFacade) {
    this.showCompProduct$ = compProductFacade.showCompProduct$;
    this.isSummaryStep$ = productFacade.isSummaryStep$;
  }

}
