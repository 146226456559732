<div (click)=onSelect()
     class="cnp-product-card">
  <div class="cnp-product-card-body">
    <img *ngIf="imgSource"        
         [style.background-color]="bgColor"
         src="{{imgSource}}"/>
  </div>
  <div class="cnp-product-card-footer">{{levelItem.description}}</div>
</div>


