export const isPresent = (item: any) => item !== null && item !== undefined;

/**
 * Polyfill for `Element.matches`.
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
 */
export const match = (element: any, selector: string): boolean => {
  const matcher = element.matches || element.msMatchesSelector || element.webkitMatchesSelector;

  if (!isPresent(matcher)) {
    return false;
  }

  return matcher.call(element, selector);
};

/**
 * Checks if a target element has the `.qty-step-item` CSS class.
 */
export const isListViewItem = (element: Element): boolean => {
  if (!isPresent(element)) {
    return false;
  }

  return match(element, '.qty-step-item');
};

export const closestWithMatch = (element: any, selector: string): Element => {
  let parent = element;

  while (parent !== null && parent.nodeType === 1) {
    if (match(parent, selector)) {
      return parent;
    }

    parent = parent.parentElement || parent.parentNode;
  }
  return null;
};

/**
 * 
 * @param start
 * @param end
 */
export const arrayNumberRange = (start: number, end: number): Array<number> => {
  var foo = [];
  for (var i = start; i <= end; i++) {
    foo.push(i);
  }
  return foo;
};



export class DecimalMath {

  static round(value: number, precision: number): number {
    return Math.round(Math.pow(10, precision) * value) / Math.pow(10, precision);
  }
  static floor(value: number, precision: number): number {
    return Math.floor(Math.pow(10, precision) * value) / Math.pow(10, precision);
  }
  static ceil(value: number, precision: number): number {
    return Math.ceil(Math.pow(10, precision) * value) / Math.pow(10, precision);
  }
  static trunc(value: number, precision: number): number {
    return Math.trunc(Math.pow(10, precision) * value) / Math.pow(10, precision);
  }
}
