import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CNPClassicComponent } from './CNP/classic/cnp-classic.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PageNotFoundComponent } from './error-page/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { QueryParamGuard } from './guards/query-param.guard';

const routes: Routes = [
  { path: '', component: CNPClassicComponent, canActivate: [QueryParamGuard,AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'error/:id', component: ErrorPageComponent },

  { path: '**', component: PageNotFoundComponent, pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
