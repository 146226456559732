<div [ngSwitch]="errorCode">

  <div *ngSwitchCase="'401'">
    <h1>Unauthorised</h1>
    <h2>{{message}}</h2>
  </div>

  <div *ngSwitchCase="'400'">
    <h1>Invalid Request</h1>
    <h2>{{message}}</h2>
  </div>
  <div *ngSwitchCase="'423'">
    <h1>The application is under maintenance</h1>
    <h2>Please come back later</h2>
  </div>
  <div *ngSwitchCase="'404'">
    <h1>Page Not Found</h1>
    <h2>{{message}}</h2>
  </div>
</div>
