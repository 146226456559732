import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { CompProduct } from '../../models/comp-product';
import { CompProductFacade } from '../../services/comp-product-facade';

export const lengthMultipleValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  const selected = control.get('selected');
  const qty = control.get('qty');
  const packaging = control.get('packaging');
  const isLengthValid = selected.value && (qty.value % packaging.value === 0);
  qty.setErrors((!selected.value || isLengthValid) ? null : { byLength: true });
  return (!selected.value || isLengthValid) ? null : { byLength: true };
}


@Component({
  selector: 'app-comp-product-container',
  templateUrl: './comp-product-container.component.html',
  styleUrls: ['./comp-product-container.component.scss']
})
export class CompProductContainerComponent implements OnInit {

  readonly imagePath: string = `${environment.webApiUrl}/Images/Product`;

  selectedValues: string[] = [];
  focusedValue: string;
  currentStepDesc: string;
  currentStepCompProducts: CompProduct[];


  form: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    public compStepFacade: CompProductFacade) {
  }

  public getImgSource(item: CompProduct): string {
    return item.imageUrl ? `${this.imagePath}/${item.childPartNum}/${item.imageUrl}` : "assets/Sans-image.png";
  }

  btnDisabled(): boolean {
    return (this.form.invalid || (this.currentStepCompProducts[0].mandatory && this.selectedValues.length === 0));
  }

  ngOnInit(): void {
    // Create Current Step Form
    this.compStepFacade.currentStep$
      .subscribe(r => {
        if (r) {
          this.currentStepDesc = r?.stepName;
          this.currentStepCompProducts = r.compProducts ?? [];
          this.selectedValues = r.selectedCompParts ?? [];
          this.createCompPart(r.compProducts);
        }
      });
  }

  nextStepClick() {
    this.compStepFacade.goToNextStep();
    this.selectedValues = [];
  }

  goToSummary() {
    this.compStepFacade.goToSummary();
  }

  updateSelectedCompProduct(partNum: string, mandatory: boolean) {

    if (this.currentStepCompProducts[0].mandatory) {
      // Reset selected part
      this.selectedValues = [];
      this.currentStepCompProducts.forEach(r => (<FormGroup>this.form.controls[r.childPartNum]).controls["selected"].setValue(false));
      (<FormGroup>this.form.controls[partNum]).controls["selected"].setValue(true);
      this.selectedValues = this.selectedValues.concat([partNum]);
      this.compStepFacade.updateCurrentStepSelectedCompPart(this.selectedValues);
      return;
    }

    if (this.selectedValues.includes(partNum)) {
      // Remove 
      this.selectedValues = this.selectedValues.filter((x: string) => partNum !== x);
    } else {
      // Add 
      this.selectedValues = this.selectedValues.concat([partNum]);
    }

    this.compStepFacade.updateCurrentStepSelectedCompPart(this.selectedValues);
  }

  onBlur() {
    this.focusedValue = "";
  }

  onFocus(partNum: string) {
    if (!this.selectedValues.includes(partNum)) {
      this.selectedValues = this.selectedValues.concat([partNum]);
      // (<FormGroup>this.form.controls[partNum]).controls["selected"].setValue(true);
      this.compStepFacade.updateCurrentStepSelectedCompPart(this.selectedValues);
      this.focusedValue = partNum;
    }
  }

  private createCompPart(compProducts: CompProduct[]) {

    for (const item of compProducts) {

      if (this.form.controls[item.childPartNum]) {
        this.form.removeControl(item.childPartNum, { emitEvent: false });
      }
      const compPartForm = this.fb.group({
        selected: [this.selectedValues.includes(item.childPartNum)],
        partNum: [item.childPartNum],
        suggQty: [item.suggQty],
        qty: [item.qty],
        packaging: [item.childPackaging],
      }, { validators: lengthMultipleValidator }
      );

      compPartForm.valueChanges.subscribe(val => {
        this.compStepFacade.updateCompProdQty(val.partNum, val.qty);
      });

      this.compStepFacade.updateCurrentStepSelectedCompPart(this.selectedValues);
      this.form.addControl(item.childPartNum, compPartForm);
    }
  }

}
