import { Component } from '@angular/core';


@Component({
  selector: 'page-not-found',
  template: '<div>Error</div>'
})
export class PageNotFoundComponent {

  constructor() { }
 

}
