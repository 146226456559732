<ng-container *ngIf="!(productFacade.showProductStepperSummary$ | async)">
  <kendo-stepper #stepper
                 *ngIf="(productFacade.steps$ | async) as steps"
                 [steps]="steps"
                 stepType="full"
                 orientation="vertical"
                 [linear]="false"
                 [(currentStep)]="currentStep">
    <ng-template kendoStepperStepTemplate let-step let-i="index">
      <div *ngIf="step.stepId !== 'NA'" class="product-step">
        <div style="display:flex;margin-top: 23px; flex-flow: row; gap:13px;">
          <span aria-hidden="true" class="k-step-indicator">
            <span class="k-step-indicator-text" style="font-size:18px;">{{i + 1}}</span>
          </span>
          <div style="display:flex;flex-flow: column;">
            <span class="step-name">{{step.stepName}}</span>
            <span class="step-desc">{{step.selectedLevelDesc}}</span>
          </div>
        </div>
        <div class="edit"><a (click)="setCurrentStep(i)">{{'lblEdit' | translate }}</a></div>
      </div>
    </ng-template>
  </kendo-stepper>
</ng-container>

<ng-container *ngIf="(productFacade.showProductStepperSummary$ | async)">
  <div class="product-step">
    <div style="display: flex; margin-top: 23px; flex-flow: row; gap: 13px;">
      <div style="display:flex;flex-flow: column;">
        <span class="step-name">{{'lblMainProduct' | translate }}</span>
        <span class="step-desc">{{productFacade.productStepperSummary$ | async}}</span>
      </div>
    </div>
    <div class="edit"><a (click)="goToQtyStep()">{{'lblEdit' | translate }}</a></div>
  </div>
</ng-container>
