import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { CompProductFacade } from '../services/comp-product-facade';                        
import { ProductFacade } from '../services/product-facade';

@Component({
  selector: 'app-cnp-classic',
  templateUrl: './cnp-classic.component.html'
})
export class CNPClassicComponent {
   
  ready$: Observable<boolean>;
  isColorStep$: Observable<boolean>;
  isQtyStep$: Observable<boolean>;
  isSummaryStep$: Observable<boolean>;
  isCompProduct$: Observable<boolean>;
    

  constructor(
    private productFacade: ProductFacade,
    private compProdFacade: CompProductFacade) {

    this.ready$ = this.productFacade.ready$;
    this.isCompProduct$ = this.compProdFacade.showCompProduct$;
    this.isColorStep$ = this.productFacade.isColorStep$;
    this.isQtyStep$ = this.productFacade.isQtyStep$;
    this.isSummaryStep$ = this.productFacade.isSummaryStep$;
  }


}
