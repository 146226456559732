import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, forkJoin, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GlobalStateService } from '../../services/global-state.service';
import { CompProduct, CompStep } from '../models';
import { ProductFacade } from './product-facade';

export interface CompProductState {
  showCompProduct: boolean;
  mainPartNum: string;
  mainPartQty: number;
  selectedParentCompProds: string[];
  selectedParentCompProdQtys: number[];
  currentStepNumber: number;
  steps: CompStep[];
}

const initialCompProductState: CompProductState = {
  showCompProduct: false,
  mainPartNum: "",
  mainPartQty: 0,
  selectedParentCompProds: [],
  selectedParentCompProdQtys: [],
  currentStepNumber: 0,
  steps: [],
}
let state: CompProductState = initialCompProductState

@Injectable({
  providedIn: 'root'
})
export class CompProductFacade {

  private store = new BehaviorSubject<CompProductState>(state);
  private state$ = this.store.asObservable();

  /////////////////////////////////////////////////////////////////////////////
  // CompProduct Streams
  /////////////////////////////////////////////////////////////////////////////
  showCompProduct$ = this.state$.pipe(
    map(state => state.showCompProduct),
    distinctUntilChanged()
  );
  currentStepNumber$ = this.state$.pipe(
    map(state => state.currentStepNumber),
    distinctUntilChanged()
  );
  isLastStep$ = this.state$.pipe(
    map(state => state.currentStepNumber + 1 === state.steps.length && !state.steps[state.currentStepNumber].isParentStep),
    distinctUntilChanged()
  );
  currentStep$ = this.state$.pipe(
    map(state => state.steps[state.currentStepNumber]),
    distinctUntilChanged()
  );
  compSteps$ = this.state$.pipe(
    map(state => {
      return state.steps.slice(0, state.currentStepNumber + 1)
    }),
    distinctUntilChanged()
  );
  showGoToSummaryBtn$ = this.state$.pipe(
    map(state => {
      return !state.steps[state.currentStepNumber].isParentStep
    }),
    distinctUntilChanged()
  )

  ////////////////////////////////////////////////////////////////////////////
  constructor(
    private http: HttpClient,
    private productFacade: ProductFacade,
    private globalStateSvc: GlobalStateService) { }

  /////////////////////////////////////////////////////////////////////////////
  // CompProduct Step Methods
  /////////////////////////////////////////////////////////////////////////////
  public resetState(): void {
    this.updateState(initialCompProductState);
  }

  public revertStepTo(stepNum: number) {
    const steps = [...state.steps];
    if (stepNum === 0 && steps[0].isParentStep) {
      steps.splice(1, steps.length - stepNum);
      this.updateState({ ...state, steps, selectedParentCompProdQtys: [], selectedParentCompProds: [], currentStepNumber: stepNum });
    }
    else {
      this.updateState({ ...state, currentStepNumber: stepNum });
    }
  }

  public showRootCompProductStep(mainPartNum: string, mainPartQty: number): void {

    this.getCompProdStep(mainPartNum, mainPartQty)
      .subscribe(steps => {
        if (steps && steps.length) {
          this.fetchCmpProduct(mainPartNum, mainPartQty, steps[0].stepId)
            .subscribe(compProd => {
              steps[0].compProducts = compProd;
              this.updateState({ ...state, mainPartNum, currentStepNumber: 0, mainPartQty, steps, showCompProduct: true });
            });
        }
        else {
          // No Complementary Product available.
          // Skip Complementary Product Steps and go to summary.
          this.goToSummary(true);
        }
      });
  }

  public getSnapShot(): CompProductState {
    return { ...state };
  }

  public updateCompProdQty(compPartNum: string, compPartQty: number) {
    const steps = [...state.steps];
    const comPart = steps[state.currentStepNumber].compProducts.find(r => r.childPartNum === compPartNum);
    if (comPart) {
      comPart.qty = compPartQty;
    }
    this.updateState({ ...state, steps });
  }

  public hideCompProduct() {
    this.updateState({ ...state, showCompProduct: false });
  }

  public updateCurrentStepSelectedCompPart(selectedCompParts: string[]) {
    const steps = [...state.steps];
    steps[state.currentStepNumber].selectedCompParts = selectedCompParts;
    this.updateState({ ...state, steps });
  }

  public goToSummary(skipComp = false): void {

    if (skipComp) {
      this.productFacade.showSummary();
      this.updateState({ ...state, showCompProduct: false });
      return;
    }
    const currentStep = state.steps[state.currentStepNumber];
    let compPartQtys: number[] = [];

    if (currentStep.isParentStep) {
      if (currentStep.selectedCompParts) {
        for (const selectedCompPart of currentStep.selectedCompParts) {
          const compPart = currentStep.compProducts.find(r => r.childPartNum === selectedCompPart);
          if (compPart) {
            compPartQtys = [...compPartQtys, compPart.qty];
          }
        }
      }

      this.getCompProdStep(
        state.mainPartNum,
        state.mainPartQty,
        currentStep.stepId,
        currentStep.selectedCompParts,
        compPartQtys)
        .subscribe(remainingSteps => {
          this.updateState({ ...state, steps: [...state.steps, ...remainingSteps] });
          const observables = remainingSteps.map(r =>
            this.fetchCmpProduct(
              state.mainPartNum,
              state.mainPartQty,
              r.stepId,
              currentStep.selectedCompParts,
              compPartQtys
            ).pipe(
              map(prods => { return { stepId: r.stepId, compProds: prods }; })
            )
          );
          forkJoin(observables).subscribe(remainingStepsData => {
            for (const stepData of remainingStepsData) {
              const step = state.steps.find(r => r.stepId === stepData.stepId);
              step.compProducts = stepData.compProds;
              const mandatoryProducts = stepData.compProds.filter(r => r.mandatory);
              if (mandatoryProducts.length) {
                step.selectedCompParts = mandatoryProducts.map(r => r.childPartNum);
              }
            }
            this.productFacade.showSummary();
            this.updateState({ ...state, showCompProduct: false });
          });
        });
    }
    else {
      const isLastStep = state.currentStepNumber + 1 === state.steps.length;
      if (isLastStep) {
        this.productFacade.showSummary();
        this.updateState({ ...state, showCompProduct: false });
      }
      else {
        const remainingSteps = state.steps.slice(state.currentStepNumber + 1, state.steps.length);
        const observables = remainingSteps.map(r =>
          this.fetchCmpProduct(
            state.mainPartNum,
            state.mainPartQty,
            r.stepId,
            currentStep.selectedCompParts,
            compPartQtys
          ).pipe(
            map(prods => { return { stepId: r.stepId, compProds: prods }; })
          )
        );
        forkJoin(observables).subscribe(remainingStepsData => {
          for (const stepData of remainingStepsData) {
            const step = state.steps.find(r => r.stepId === stepData.stepId);
            step.compProducts = stepData.compProds;
            const mandatoryProducts = stepData.compProds.filter(r => r.mandatory);
            if (mandatoryProducts.length) {
              step.selectedCompParts = mandatoryProducts.map(r => r.childPartNum);
            }
          }
          this.productFacade.showSummary();
          this.updateState({ ...state, showCompProduct: false });
        });
      }
    }
  }

  public goToNextStep() {
    const currentStep = state.steps[state.currentStepNumber];

    let compPartQtys: number[] = [];

    if (currentStep.isParentStep) {

      if (currentStep.selectedCompParts) {
        for (const selectedCompPart of currentStep.selectedCompParts) {
          const compPart = currentStep.compProducts.find(r => r.childPartNum === selectedCompPart);
          if (compPart) {
            compPartQtys = [...compPartQtys, compPart.qty];
          }
        }
      }

      this.getCompProdStep(
        state.mainPartNum,
        state.mainPartQty,
        currentStep.stepId,
        currentStep.selectedCompParts,
        compPartQtys)
        .subscribe(newSteps => {

          const steps = [...state.steps, ...newSteps];

          this.fetchCmpProduct(state.mainPartNum, state.mainPartQty, steps[state.currentStepNumber + 1].stepId, currentStep.selectedCompParts, compPartQtys)
            .subscribe(compProd => {
              steps[state.currentStepNumber + 1].compProducts = compProd;
              this.updateState({ ...state, steps, currentStepNumber: state.currentStepNumber + 1, selectedParentCompProds: currentStep.selectedCompParts, selectedParentCompProdQtys: compPartQtys });
            });

        });
    }
    else {
      this.fetchCmpProduct(state.mainPartNum, state.mainPartQty, state.steps[state.currentStepNumber + 1].stepId, state.selectedParentCompProds, state.selectedParentCompProdQtys)
        .subscribe(compProd => {
          const steps = [...state.steps];
          const currentStep = state.steps[state.currentStepNumber + 1];
          currentStep.compProducts = compProd;
          this.updateState({ ...state, steps, currentStepNumber: state.currentStepNumber + 1, });
        });
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Private Methods
  /////////////////////////////////////////////////////////////////////////////
  private updateState(newState: CompProductState) {
    this.store.next(state = newState);
  }

  private fetchCmpProduct(mainPartNum: string, mainPartQty: number, currentStepId: string, parentCmpPartNums?: string[], parentCmpPartQtys?: number[]): Observable<CompProduct[]> {

    var custNum = this.globalStateSvc.custNum;
    var shipToNum = this.globalStateSvc.shipToNum;


    let params = new HttpParams();
    params = params.append('custNum', custNum);
    params = params.append('shipToNum', shipToNum);
    params = params.append('mainPartNum', mainPartNum);
    params = params.append('mainPartQty', mainPartQty);
    params = params.append('currentStepId', currentStepId);

    if (parentCmpPartNums) {
      for (const parentCmpPartNum of parentCmpPartNums) {
        params = params.append('parentCmpPartNums', !parentCmpPartNum ? "" : parentCmpPartNum);
      }
    }

    if (parentCmpPartQtys) {
      for (const parentCmpPartQty of parentCmpPartQtys) {
        params = params.append('parentCmpPartQtys', !parentCmpPartQty ? '' : parentCmpPartQty);
      }
    }

    return this.http.get<CompProduct[]>(`${environment.webApiUrl}/CompProduct`, { params }).pipe(
      map(compProds => compProds.map(row => { row.qty = row.suggQty; return row; }))
    )
  }

  private getCompProdStep(mainPartNum: string, mainPartQty: number, parentStepId?: string, parentCmpPartNums?: string[], parentCmpPartQtys?: number[]): Observable<CompStep[]> {
    let params = new HttpParams();
    params = params.append('mainPartNum', mainPartNum);
    params = params.append('mainPartQty', mainPartQty);
    params = params.append('parentStepId', !parentStepId ? "" : parentStepId);

    if (parentCmpPartNums) {
      for (const parentCmpPartNum of parentCmpPartNums) {
        params = params.append('parentCmpPartNums', !parentCmpPartNum ? "" : parentCmpPartNum);
      }
    }

    if (parentCmpPartQtys) {
      for (const parentCmpPartQty of parentCmpPartQtys) {
        params = params.append('parentCmpPartQtys', !parentCmpPartQty ? '' : parentCmpPartQty);
      }
    }

    return this.http.get<CompStep[]>(`${environment.webApiUrl}/CompProduct/Steps/`, { params })
  }
}
