import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalStateService } from './services/global-state.service';
import { SVGIcon, chevronDownIcon } from "@progress/kendo-svg-icons";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  public chevronDown: SVGIcon = chevronDownIcon

  private frqLanguages = [
    { value: 'enu', text: 'Anglais' },
    { value: 'frq', text: 'Français' }
  ];

  private enuLanguages = [
    { value: 'enu', text: 'English' },
    { value: 'frq', text: 'French' }
  ];

  constructor(
    public globalStateSvc: GlobalStateService,
    private translate: TranslateService) {
  }

  getLanguages(): Array<{ value: string, text: string }> {
    if (this.translate.currentLang == 'frq') {
      return this.frqLanguages;
    }
    else {
      return this.enuLanguages;
    }
  }

  languageChange(langNameID) {
    this.translate.use(langNameID);
  }

}

