import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalStateService } from '../services/global-state.service';

@Injectable()
export class AddTokenHeaderHttpRequestInterceptor implements HttpInterceptor {

  constructor(private authSvc: AuthenticationService, private globalStatesvc: GlobalStateService, private translate: TranslateService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    var token = this.authSvc.token;
    var langNameID = this.translate.currentLang;
    
    if (token || langNameID) {
      let newRequest = request.clone(
        {
          headers: request.headers.set(
            'Authorization',
            `Bearer ${token}`
          )
            .set('LangNameID', (langNameID ? langNameID : "enu"))
            .set('X-Auth-Company', this.globalStatesvc.company)
        }
      );
      return next.handle(newRequest);
    }

    return next.handle(request);
  }
}
