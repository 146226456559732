import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Product, UOM } from '../../../models';
import { ProductFacade, SelectedProduct } from '../../../services/product-facade';

@Component({
  selector: 'app-base-qty-item',
  template: ``
})
export abstract class BaseQtyItemComponent implements OnInit, OnDestroy {

  public selectedUOM: UOM;
  subscriptions = new Subscription();

  focused: string;
  value: string;

  qtyItemForm = new FormGroup({
    isMaibecExpress: new FormControl(false),
    sellingUOM: new FormControl(''),
    sellingUOMDesc:new FormControl(''),
    qty: new FormControl(0),
    totalSellingQty: new FormControl(0),
    totalDefaultUOMQty: new FormControl(0),
    scrap: new FormControl(0),
    quantities: new FormArray([]),
    comment: new FormControl("")
  });
   
  @Input()
  public part: Product;
 
  @Input()
  public selectedProductForm: FormGroup;

  constructor(
    public productFacade: ProductFacade) {
  };


  ngOnInit(): void {
    // Set Default selected UOM
    this.selectedUOM = this.part.partUOMs.find(r => r.visible && r.uomCode === this.part.defaultUOM);    
    if (!this.selectedUOM) {
      this.selectedUOM = this.part.partUOMs[0];
    }   
    this.qtyItemForm.controls.sellingUOM.setValue(this.selectedUOM.uomCode);
    this.qtyItemForm.controls.sellingUOMDesc.setValue(this.selectedUOM.uomDesc);
    
    this.subscriptions.add(this.selectedProductForm.get('partNum').valueChanges.subscribe(r => {
      this.value = r
    }));

    // UOM Changing
    this.subscriptions.add(this.qtyItemForm.controls.sellingUOM.valueChanges.subscribe(sellingUOM => {
      this.qtyItemForm.controls.sellingUOMDesc.setValue(this.part.partUOMs.find(r => r.uomCode === sellingUOM).uomDesc);
      this.qtyItemForm.controls.totalSellingQty.setValue(this.computeTotalSellingQty());
      this.updateSelectedProductForm();
    }));

    // Quantity Changing
    this.subscriptions.add(this.qtyItemForm.controls.qty.valueChanges.subscribe(qty => {
      this.qtyItemForm.controls.totalSellingQty.setValue(this.computeTotalSellingQty());
      this.updateSelectedProductForm();
    }));

    // Scrap Changing
    this.subscriptions.add(this.qtyItemForm.controls.scrap.valueChanges.subscribe(scrap => {
      this.qtyItemForm.controls.totalSellingQty.setValue(this.computeTotalSellingQty());
      this.updateSelectedProductForm();
    }));

    this.selectedProductForm.addControl(this.part.partNum, this.qtyItemForm);

    // Set Selected product value if we are back from Summary
    this.subscriptions.add(this.productFacade.selectedProduct$.subscribe(r => {
      if (r.partNum == this.part.partNum) {
        this.setFormValues(r);
      }

    }));
  };

  abstract setFormValues(r: SelectedProduct): void;

  abstract computeTotalSellingQty(): number;
  
  private updateSelectedProductForm(): void {

    const formValues = this.qtyItemForm.getRawValue();
    const newValues = {
      qtyType: this.part.qtyType,
      partNum: this.part.partNum,
      partDesc: this.part.partDescription,
      isMaibecExpress: this.part.isMaibecExpress,
      sellingUOM: formValues.sellingUOM,
      sellingUOMDesc: formValues.sellingUOMDesc,
      qty: formValues.qty,
      totalSellingQty: formValues.totalSellingQty,
      totalDefaultUOMQty: formValues.totalDefaultUOMQty,
      scrap: formValues.scrap,
      comment: formValues.comment
    };
    this.selectedProductForm.patchValue(newValues);
    this.selectedProductForm.controls['quantities'] = this.qtyItemForm.controls.quantities;
  };

  onBlur() {
    this.focused = '';
  };

  onFocus(value: string) {
    this.focused = value;
    this.updateSelectedProductForm();
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.selectedProductForm.removeControl(this.part.partNum);
  };

}
