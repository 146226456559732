<label class="qty-step-item"
       [class.qtytype-active]="value === part.partNum"
       [class.qtytype-focused]="focused === part.partNum">
    <div [formGroup]="selectedProductForm">
        <input kendoRadioButton
               type="radio"
               formControlName="partNum"
               name="partNum"
               [size]="'large'"
               value="{{part.partNum}}"
               (blur)="onBlur()"
               (focus)="onFocus(part.partNum)">
        <label *ngIf="!part.emptyQtyTypeLabel" class="radio-button-label">{{ 'lblSpecificLength' | translate }}</label>
        <h4>{{part.partNum}} : {{part.partDescription | uppercase}}</h4>
    </div>
    <div>
        <form [formGroup]="qtyItemForm">
            <div formArrayName="quantities">
                <table width="500px" style="border-collapse:collapse">
                    <colgroup>
                        <col style="width:50%" />
                        <col style="width:20%" />
                        <col style="width:20%" />
                        <col style="width:20%" />
                    </colgroup>
                    <tr style="border-bottom:1px solid black;">
                        <th>{{ 'lblQty' | translate }}</th>
                        <th></th>
                        <th>{{ 'lblLength' | translate }}</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td colspan="4" style="height:5px;"></td>
                    </tr>
                    <ng-container *ngFor="let quantity of qtyItemForm.controls.quantities.controls; let i = index;" [formGroupName]="i">
                        <tr>
                            <td>
                                <kendo-numerictextbox formControlName="qty"
                                                      [min]="1"
                                                      format="n0"
                                                      [decimals]="0"
                                                      (blur)="onBlur()"
                                                      [spinners]="false"
                                                      (focus)="onFocus(part.partNum)"></kendo-numerictextbox>
                            </td>
                            <td style="text-align:center;">X</td>
                            <td>
                                <kendo-dropdownlist [data]="lengths"
                                                    [svgIcon]="chevronDown"
                                                    fillMode="outline"
                                                    formControlName="length"
                                                    (blur)="onBlur()"
                                                    (focus)="onFocus(part.partNum)"></kendo-dropdownlist>
                            </td>
                            <td style="text-align:right">
                                <kendo-svg-icon [icon]="trash" (click)="removeLineAt(i)"></kendo-svg-icon>
                                <span class="k-icon k-i-delete icon-color"></span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div class="k-form-error k-text-start"
                                     [translate]="quantity.errors?.['message']"
                                     [translateParams]="{ maxQty: quantity.errors?.['maxQty']}"
                                     *ngIf="quantity.errors?.['ls']"></div>
                            </td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td colspan="4" style="text-align:right">
                            <button kendoButton
                                    class="cnp-button-primary"
                                    [svgIcon]="plus"
                                    icon="plus"
                                    (click)="addLine()">
                                {{ 'btnAdd' | translate }}
                            </button>
                        </td>
                    </tr>
                </table>
                <div style="margin-bottom:10px">
                    <strong>{{ 'lblTotalQtySold' | translate }}</strong>:
                    <span>{{qtyItemForm.controls.totalSellingQty.value}} {{qtyItemForm.controls.sellingUOMDesc.value}}</span>
                </div>
            </div>
        </form>
    </div>
</label>


