<label class="qty-step-item"
       [class.qtytype-active]="value === part.partNum"
       [class.qtytype-focused]="focused === part.partNum">
  <div [formGroup]="selectedProductForm">
    <input kendoRadioButton
           type="radio"
           formControlName="partNum"
           name="partNum"
           value="{{part.partNum}}"
           (blur)="onBlur()"
           (focus)="onFocus(part.partNum)">
    <label *ngIf="!part.emptyQtyTypeLabel" class="radio-button-label">{{ 'lblRandomLength' | translate }}</label>
  </div>
  <div [formGroup]="qtyItemForm">
    <h4>{{part.partNum}} : {{part.partDescription | uppercase}}</h4>
    <label style="font-weight:bold">{{ 'lblQty' | translate }}:</label>
    <kendo-numerictextbox [style.width.px]="150"
                          style="margin:0 10px;"
                          placeholder="{{ 'lblQuantity' | translate }}"
                          format="n0"
                          [decimals]="0"
                          [min]="0"
                          (blur)="onBlur()"
                          [spinners]="false"
                          (focus)="onFocus(part.partNum)"
                          [maxlength]="7"
                          formControlName="qty"></kendo-numerictextbox>
    <kendo-dropdownlist style="width:100px"
                        [svgIcon]="chevronDown"
                        fillMode="outline"
                        (blur)="onBlur()"
                        (focus)="onFocus(part.partNum)"
                        [data]="visibleUOMs"
                        textField="uomDesc"
                        valueField="uomCode"
                        [valuePrimitive]="true"
                        formControlName="sellingUOM"></kendo-dropdownlist>
    <span style="margin:0 10px;">+</span>
    <kendo-dropdownlist style="width:100px"
                        [svgIcon]="chevronDown"
                        fillMode="outline"
                        (blur)="onBlur()"
                        (focus)="onFocus(part.partNum)"
                        [data]="scrapPrecent"
                        formControlName="scrap"> </kendo-dropdownlist>
    <label style="display:inline; margin-left:10px">{{ 'lblCuttingLoss' | translate }}</label>

    <div style="margin-bottom: 10px; margin-top: 10px">
      <strong>{{ 'lblTotalQtySold' | translate }} : </strong>
      <span>{{qtyItemForm.controls.totalSellingQty.value }} {{qtyItemForm.controls.sellingUOMDesc.value}}  {{ 'lblIncludingLoss' | translate }}</span>
    </div>
  </div>
</label>


