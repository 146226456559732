import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { empty, Observable } from 'rxjs';
import { NotificationService } from '@progress/kendo-angular-notification';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private notificationSvc: NotificationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse.status === 0) {
            this.notificationSvc.show({
              content: "Enable to contact CNP Web API Server. Contact your Administrator",
              position: { horizontal: 'center', vertical: 'top' },
              animation: { type: 'slide', duration: 800 },
              type: { style: 'error', icon: true }
            });
          }
          else if (httpErrorResponse.status === 401) {
            this.router.navigate(['error',401])
          }

          else if (httpErrorResponse.status === 423) {
            this.router.navigate(['error', 423])
          }
          else if (httpErrorResponse.status === 400) {
            this.notificationSvc.show({
              content: httpErrorResponse.error.title + JSON.stringify(httpErrorResponse.error.errors),
              position: { horizontal: 'center', vertical: 'top' },
              animation: { type: 'slide', duration: 800 },
              type: { style: 'error', icon: true }
            });
          }
          else {

            if (httpErrorResponse.status !== 401) {
              
              this.notificationSvc.show({
                content: JSON.stringify(httpErrorResponse.error),
                position: { horizontal: 'center', vertical: 'top' },
                animation: { type: 'slide', duration: 800 },
                type: { style: 'error', icon: true },
                closable: true
              });
            }
          }
          return empty();
        }))
  }
}
