import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Principal } from '../models/principal';

let state: Principal = {
  accessToken: "",
  expiresOn: null,
  tokenType: "",
  identity: {
    langNameID: "enu",
    userId: "",
    userName: ""
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private store = new BehaviorSubject<Principal>(state);
  private state$ = this.store.asObservable();

  constructor(
    private http: HttpClient) { }

  public get isAuthenticated(): boolean {
    //if (!environment.production) {
    //  return true;
    //}
    return this.store.value.accessToken !== "";
  }

  public get token(): string {
    return this.store.value.accessToken;
  }
  public get tokenType(): string {
    return this.store.value.tokenType;
  }
  public get userName(): string {
    return this.store.value.identity.userName;
  }
    public get langNameID(): string {
    return this.store.value.identity.langNameID;
  }

  public autoLogin(token: string): Observable<boolean> {

    return this._autoLogin(token).pipe(
      map(principal => {
        if (principal) {
          this.updateState(principal);
          return true;
        }
        else {
          return false;
        }
      })
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // Private Methods
  /////////////////////////////////////////////////////////////////////////////
  private updateState(newState: Principal) {
    this.store.next(state = newState);
  }

  private _autoLogin(token: string/*,userID: string*/): Observable<Principal> {
    return this.http.get<Principal>(`${environment.webApiUrl}/Authentication/AutoLogin/${token}`);
  }

}
