<div *ngIf="(ready$ | async)" style="display:flex; flex-flow:column;">
  <app-stepper></app-stepper>

  <app-product-step></app-product-step>

  <app-color-step *ngIf="(isColorStep$ | async)"></app-color-step>

  <app-quantity-step *ngIf="(isQtyStep$ | async) && !(isCompProduct$ | async)"></app-quantity-step>

  <app-comp-product-container *ngIf="(isCompProduct$ | async)"></app-comp-product-container>

  <app-summary-step *ngIf="(isSummaryStep$ | async)"></app-summary-step>
</div>
