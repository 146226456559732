import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { LevelItem } from '../../../models/level-item';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductCardComponent {

  @HostBinding('class') class = 'cnp-card';

  readonly imagePath: string = `${environment.webApiUrl}/Images/Level`;


  @Output()
  cardClick = new EventEmitter<string>();

  @Input()
  public levelItem: LevelItem;

  public get imgSource(): string {
    return this.levelItem.imageUrl ? `${this.imagePath}/${this.levelItem.levelId}/${this.levelItem.imageUrl}` : "assets/Sans-image.png";
  }

  public get bgColor(): string {
    return this.levelItem.bgHexCode ? this.levelItem.bgHexCode : "";
  }
  onSelect() {
    this.cardClick.emit();
  }

}
