import { Component } from '@angular/core';
import { ProductFacade, SelectedProduct } from '../../../../services/product-facade';
import { DecimalMath } from '../../../../utils/utils';
import { BaseQtyItemComponent } from '../base-qty-item.component';
import { SVGIcon, chevronDownIcon } from '@progress/kendo-svg-icons';
import { UOM } from '../../../../models';

@Component({
  selector: 'app-fixed-item',
  templateUrl: './fixed-item.component.html',
  styleUrls: ['../base-qty-item.component.scss']
})
export class FixedItemComponent extends BaseQtyItemComponent {

  public chevronDown: SVGIcon = chevronDownIcon

  constructor(public override productFacade: ProductFacade) {
    super(productFacade);
  };
    
  override ngOnInit(): void {
    super.ngOnInit();    
  }

  get visibleUOMs(): Array<UOM> {
    return this.part.partUOMs.filter(r => r.visible == true);
  }

   
  setFormValues(r: SelectedProduct): void {
    this.qtyItemForm.controls.sellingUOM.setValue(r.sellingUOM, { emitEvent: false });
    this.qtyItemForm.controls.sellingUOMDesc.setValue(r.sellingUOMDesc, { emitEvent: false });
    this.qtyItemForm.controls.qty.setValue(r.qty);
  }

  computeTotalSellingQty(): number {
    const sellingUOM = this.part.partUOMs.find(r => r.uomCode === this.qtyItemForm.controls.sellingUOM.value);
    const desiredQty = this.qtyItemForm.controls.qty.value;
    const factor = sellingUOM.convFactor;
    const sellingQty = (Math.ceil(Math.ceil(desiredQty * factor) / this.part.minLength) * this.part.minLength) / factor;
    let totalSellingQty: number;

    switch (sellingUOM.rounding) {
      case "RND":
        totalSellingQty = DecimalMath.round(sellingQty, sellingUOM.numOfDec);
        break;
      case "UP":
        totalSellingQty = DecimalMath.round(sellingQty, sellingUOM.numOfDec);
        break;
      case "DWN":
        totalSellingQty = DecimalMath.round(sellingQty, sellingUOM.numOfDec);
        break;
      default:
        totalSellingQty = DecimalMath.trunc(sellingQty, sellingUOM.numOfDec);
    }

   // Compute default uom qty
    if (sellingUOM.uomCode == this.part.defaultUOM) {
      this.qtyItemForm.controls.totalDefaultUOMQty.setValue(totalSellingQty);
    }
    else {
      const defaultUOM = this.part.partUOMs.find(r => r.uomCode === this.part.defaultUOM);
      let totalDefaultUOMQty = totalSellingQty * sellingUOM.convFactor;

      switch (defaultUOM.rounding) {
        case "RND":
          totalDefaultUOMQty = DecimalMath.round(totalDefaultUOMQty, defaultUOM.numOfDec);
          break;
        case "UP":
          totalDefaultUOMQty = DecimalMath.round(totalDefaultUOMQty, defaultUOM.numOfDec);
          break;
        case "DWN":
          totalDefaultUOMQty = DecimalMath.round(totalDefaultUOMQty, defaultUOM.numOfDec);
          break;
        default:
          totalDefaultUOMQty = DecimalMath.trunc(totalDefaultUOMQty, defaultUOM.numOfDec);
      }
      this.qtyItemForm.controls.totalDefaultUOMQty.setValue(totalDefaultUOMQty);
    }
    
    return totalSellingQty;

  }

}
